'use strict'

function fromPercent(value, dimension) {
    return (value - 0.5)*dimension;
}

function toPercent(value, dimension) {
    return value/dimension + 0.5;
}

function computeLocationX(x, documentWidth) {
    const xPx = fromPercent(x, documentWidth);
    const anchorLocation = documentWidth / 2.;
    return anchorLocation + xPx;
}

function computeLocationY(y, documentHeight) {
    const yPy = fromPercent(y, documentHeight);
    const anchorLocation = documentHeight / 2.;
    return anchorLocation + yPy;
}

function computeLocation(position, documentSize) {
    return { x: computeLocationX(position.x, documentSize.width), y: computeLocationY(position.y, documentSize.height)};
}

function computeLocationParams(componentPosition, documentSize) {
    const anchorLocation = { x: (documentSize.width) / 2., y: (documentSize.height) / 2. };
    const xPx = componentPosition.x - anchorLocation.x;
    const yPx = componentPosition.y - anchorLocation.y;
    return { x: toPercent(xPx, documentSize.width), y: toPercent(yPx, documentSize.height) }
}

export { computeLocation, computeLocationParams }