<template>
<div class='row is-justify-content-center'>
	<b-tooltip :label="$t('BUTTONS.UNDO')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("undo")' icon-left="undo" :disabled='true'></b-button>
	</b-tooltip>
	<b-tooltip :label="$t('BUTTONS.REDO')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("redo")' icon-left="redo" :disabled='true'></b-button>
	</b-tooltip>
	<b-tooltip :label="$t('BUTTONS.BRING_FORWARD')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("forward")' icon-left="arrange-bring-forward" :disabled='!forwardEnabled'></b-button>
	</b-tooltip>
	<b-tooltip :label="$t('BUTTONS.SEND_BACKWARD')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("backward")' icon-left="arrange-send-backward" :disabled='!backwardEnabled'></b-button>
	</b-tooltip>
	<b-tooltip :label="$t('BUTTONS.DUPLICATE')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("duplicate")' icon-left="content-duplicate"></b-button>
	</b-tooltip>
	<b-tooltip :label="$t('BUTTONS.DELETE')" style='padding: 0px 3px'>
		<b-button icon @click='$emit("remove")' icon-left="delete" type="is-danger"></b-button>
	</b-tooltip>
</div>
</template>


<script>

export default {
	name: 'visual-input-toolbar',
	components: { },
	props: {
		forwardEnabled: { type: Boolean, default: true },
		backwardEnabled: { type: Boolean, default: true },
	},
	data() { return {
	}},
	computed: {
	},
	methods: {

	},
	mounted() {
	},
}
</script>


<style scoped>
</style>
