'use strict'

import BackgroundVidaComponent from "@/vida/components/backgroundvidacomponent";
import InvalidDataError from '@/vida/invaliddataerror.js';
import SvgVidaComponent from "@/vida/components/svgvidacomponent";
import TextVidaComponent from "@/vida/components/textvidacomponent";
import migrate from '@/vida/versions/migrater.js';

import Konva from 'konva';

export default class Vida {

    constructor(canvasId, blind) {
        this.setupCanvas(canvasId)
        this.background = new BackgroundVidaComponent(this, canvasId);
        this.document = { };
        this.components = [];
        this.blind = blind;
    }

    selectedComponent() {
        return this.selectedComponent_;
    }

    selectComponent(key) {
        if (this.selectedComponent_ && key === this.selectedComponent_) return;
        if (this.selectedComponent_ && this.selectedComponent_ !== this.document.id) {
            this.components.find((component) => component.id === this.selectedComponent_).unselect();
        }

        this.selectedComponent_ = key;
        this.onSelect(key);
        if (!key || key === this.document.id) return
        this.components.find((component) => component.id ===key).select();
    }

    documentParams() {
        return this.document;
    }

    componentsKeys() {
        return this.components.map((component) => component.id);
    }

    componentParams(key) {
        if (key === this.document.id) return this.documentParams;
        const component = this.components.find((component) => component.id == key);
        console.assert(component);
        return component.toJson();
    }

    toJson() {
        return {
            version: this.version,
            document: this.document,
            components: this.components.map((component) => component.toJson())
        }
    }

    loadJson(template) {
        const updatedTemplate =  migrate(template);
        this.version = template.version
        return new Promise((resolve) => {
            const promises = [];
            this.documentSize = { width: updatedTemplate.document.width, height: this.document.height };
            this.documentSize.width = Math.max(1, this.documentSize.width);
            this.documentSize.height = Math.max(1, this.documentSize.height);
            promises.push(this.updateDocument(updatedTemplate.document));
            updatedTemplate.components = updatedTemplate.components.sort((a, b) => a.zIndex > b.zIndex);
            for (const component of updatedTemplate.components) {
                promises.push(this.setupComponent(component));
            }
            Promise.all(promises).then(() => resolve());
        });
    }

    checkVersion(template) {
        if (template.version === undefined) throw new InvalidDataError(`Invalid template. It should contain a version field`);

        if (template.version === "1.0.0") return;
        throw new InvalidDataError(`Invalid template version ${template.version}`);
    }

    zoom(factor = null) {
        // compute factor to fit screen
        if (!factor) {
            // set to 100% if content is smaller than screen
            factor = Math.min(this.layer.width() / this.documentSize.width, this.layer.height() / this.documentSize.height);
            factor *= 0.95
        }
        this.stage.setScale({ x: factor, y: factor });
        this.layer.setX((this.layer.width() / this.stage.scale().x - this.documentSize.width) / 2);
        this.layer.setY((this.layer.height() / this.stage.scale().y - this.documentSize.height) / 2);
        this.draw();
        return factor;
    }


    setupCanvas(canvasId) {
        const canvas = document.getElementById(canvasId);
        const canvasParent = canvas.parentElement;

        const width = canvas.width ? canvas.width : canvasParent.clientWidth;
        const height = canvas.height ? canvas.height : canvasParent.clientHeight;

        this.layer = new Konva.Layer({ draggable: true });
        this.stage = new Konva.Stage({
            container: canvasId,
            width: width,
            height: height,
        });
        this.stage.add(this.layer);
        this.layer.draw();
        // this.layer.toggleHitCanvas();
    }

    handleResize() {
        if (!this.stage) return;
        const canvas = this.stage.container();
        const canvasParent = canvas.parentElement;

        const width = canvasParent.clientWidth;
        const height = canvasParent.clientHeight;

        this.stage.setAttrs({ width, height });
        this.zoom();
    }

    updateDocument(documentParams) {
        if(documentParams.width !== undefined) documentParams.width = Math.max(1, documentParams.width);
        if(documentParams.height !== undefined) documentParams.height = Math.max(1, documentParams.height);
        // TODO  fonction récursive propre et fonctionnelle...
        return new  Promise((resolve) => {
            for (const key in documentParams) {
                const value = documentParams[key];
                if (typeof value !== 'object') {
                    this.document[key] = documentParams[key];
                } else {
                    if(!this.document[key]) this.document[key] = {};
                    for (const secondKey in value) {
                        if (typeof value[secondKey] !== 'object') {
                            this.document[key][secondKey] = documentParams[key][secondKey];
                        } else {
                            this.document[key][secondKey] = {};
                            for (const thirdKey in value[secondKey]) { // colors
                                console.assert(typeof value[secondKey][thirdKey] !== 'object');
                                this.document[key][secondKey][thirdKey] = value[secondKey][thirdKey];
                            }
                        }
                    }
                }
            }

            if (documentParams.width && documentParams.height) {
                this.documentSize = { width: documentParams.width, height: documentParams.height};
                this.layer.setX((this.layer.width() / this.stage.scale().x - this.documentSize.width) / 2);
                this.layer.setY((this.layer.height() / this.stage.scale().y - this.documentSize.height) / 2);
            }

            const promises = [];
            promises.push(this.background.update(documentParams, { documentSize: this.documentSize }));
            if (documentParams.width || documentParams.height) {
                if (documentParams.width) this.documentSize.width = documentParams.width;
                if (documentParams.height) this.documentSize.height = documentParams.height;
                for (const component of this.components) {
                    promises.push(component.update({}, this.documentSize));
                }
            }
            Promise.all(promises).then(() => resolve());
        });
    }

    
    updateComponent(key, options) {
        const component = this.components.find((component) => component.id == key);
        if (!component) return;
        if (options.zIndex) {
            let newZIndex = options.zIndex;
            if (newZIndex < component.zIndex) {
                newZIndex -= 0.1;
            } else {
                newZIndex += 0.1;
            }
            this.components = this.components.sort((a, b) => {
                return (a.id == key ? newZIndex : a.zIndex) > (b.id == key ? newZIndex : b.zIndex);
            });
            this.rearrangeZIndices();
            options.zIndex = undefined;
        }
        
        return component.update(options, this.document);
    }
    
    removeComponent(id) {
        const component = this.components.find((component) => component.id == id);
        if (!component) return;
        component.remove();
        const indexOf = this.components.indexOf(component);
        this.components.splice(indexOf, 1);
        if (id === this.selectedComponent_) this.selectedComponent_ = undefined;
    }

    createComponent(options) {
        options.zIndex = Math.max.apply(Math, this.components.map((comp) => comp.toJson().zIndex)) + 1;
        return this.setupComponent(options);
    }

    setupComponent(componentParams) {
        if (componentParams.type === 'text') {
            const textComponent = new TextVidaComponent(this, componentParams.id);
            this.components.push(textComponent);
            return textComponent.update(componentParams, this.documentSize);
        } else if (componentParams.type === 'svg') {
            const svgComponent = new SvgVidaComponent(this, componentParams.id);
            this.components.push(svgComponent);
            return svgComponent.update(componentParams, this.documentSize);
        } else {
            throw new InvalidDataError(`Unknown component type ${componentParams.type}`);
        }
    }

    registerComponent(konvaElement) {
        this.components = this.components.sort((a, b) => a.zIndex > b.zIndex);
        this.rearrangeZIndices();
        this.layer.add(konvaElement);
    }

    registerTransformer(konvaElement) {
        this.layer.add(konvaElement);
    }

    rearrangeZIndices() {
        this.background.update({ zIndex:  0 });
        let k = 1;
        for (const component of this.components) {
            component.update({ zIndex: k });
            ++k;
        }
        this.background.bringClipperForward();
    }

    draw() {
        this.layer.draw();
    }

    destroy() {
        console.log("Todo vida.destroy()")
    }

    onComponentUpdated(/*id*/) {
        console.assert(false, 'onComponentUpdated should be overriden')
    }
    
    onSelect(/*id*/) {
        console.assert(false, 'onSelect should be overriden')
    }
}