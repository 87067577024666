'use strict';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
const langs = require('@/assets/langs.json');


Vue.use(VueI18n);

// load default locale
export const DEFAULT_LOCALE = 'fr';
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

const newLocale = langs.find(l => l.code === locale) ? locale : DEFAULT_LOCALE;

const loadedLanguages = [newLocale];
const message = require(/* webpackChunkName: "i18n-[request]" */`@/assets/i18n/${newLocale}.json`);
//=================================================================================================================

export const i18n = new VueI18n({
	locale: newLocale,
	fallbackLocale: DEFAULT_LOCALE,
	silentFallbackWarn: true,
	messages: { [newLocale]: message }
});

//=================================================================================================================


export const loadLanguage = async lang => {
	// requested lang is already the current locale
	if (i18n.locale === lang) { return }
	
	// requested lang is not available
	const isLangAvailable = langs.find(l => l.code === lang);
	if (!isLangAvailable) { return }

	// load locale if needed
	if (!loadedLanguages.includes(lang)) {
		const message = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${lang}.json`);
		i18n.setLocaleMessage(lang, message);
		loadedLanguages.push(lang);
	}

	// set locale globally
	i18n.locale = lang;
};


// load user's preferred language
// TODO voir ce qu'on en fait
// loadLanguage(window.navigator.language.split('-')[0]);
