<template>
    <div>
        <vida-navbar active='fonts'/>
        <section class="hero is-primary">
            <div class="hero-body">
                <h1 class="title">{{ $t('NAVBAR.FONTS') }}</h1>
                <p class='subtitle'>{{ $t('FONTS.PAGE_DESCRIPTION') }}</p>
            </div>
        </section>

        <section style='padding: 20px'>
            <div class='card font-card' v-for='font, key in fonts' :key='key'>
                <header class='card-header'>
                    <p class='card-header-title' :style='{ fontFamily: font.fontName}'>{{ font.name }}</p>
                </header>
                <div class='card-content' :style='{ fontFamily: font.fontName}'>
                    <p class='font-pangram'>{{ $t('FONTS.PANGRAM') }}</p>
                    <p class='font-numbers'>{{ $t('FONTS.NUMBERS') }}</p>
                    <p class='font-special'>{{ $t('FONTS.SPECIAL') }}</p>
                </div>
                <footer class='card-footer'>
                    <a :href="`/static/fonts/${ font.fontUrl }`" class="card-footer-item" download>{{ $t('BUTTONS.DOWNLOAD') }}</a>
                </footer>
            </div>
        </section>
    </div>
</template>

<script>
import VidaNavbar from '@/components/navbar'
import fonts from '@/assets/local/data/fonts.json';

export default {
    name: 'FontsPage',
    metaInfo: function() {
        return ({
            title: `${this.$t("NAVBAR.FONTS")} - ${this.$localConfig.id}`,
            titleTemplate: '%s | Aktivisda.earth',
			meta: [
				{ vmid: 'description', name: 'description', content: `${this.$t("FONTS.PAGE_DESCRIPTION")}`  },
				{ property: 'og:title', vmid: 'og:title', content: `${this.$t("NAVBAR.FONTS")} | ${this.$localConfig.id}.aktivisda` },
				{ property: 'og:type', vmid: 'og:type', content: 'website' },
				{ property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
				{ property: 'og:description', vmid: 'og:description', content: `${this.$t("FONTS.PAGE_DESCRIPTION")}` },
			]				
        });
    },
    components: { VidaNavbar },
    data: () => ({
        fonts
    })
};
</script>

<style scoped lang='scss'>
.font-card {
    // background-color: black;
    // color: white;
    margin: 20px 0px;
    // text-align: left;
    // padding: 20px;
    .card-header-title {
        text-transform: uppercase;
    }

    .card-content p {
        padding: 5px;
    }

    .font-numbers {
        background-color: var(--primary-color);
        color: var(--primary-text-color);
    }
    .font-special {
        background-color: var(--secondary-color);
        color: var(--secondary-text-color);
    }
}
</style>
