<template>
	<div>
		<b-dropdown aria-role="list" expanded>
			<template #trigger="{ active }">
				<b-button type="is-light" :icon-right="active ? 'menu-up' : 'menu-down'" :key='selectedFont' expanded>
					<span :style='"font-family:" + selectedFontName'>{{ selectedFont }}</span>
				</b-button>
			</template>

			<b-dropdown-item v-for="font in fonts" :value="font.name" :key="font.name" aria-role="listitem"
				@click='selectedFontName = font.fontName'
				:disabled="font.name === selectedFont">
				<span :style='"font-family:" + font.fontName'>{{ font.name }}</span>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>


<script>
import { fonts } from '@/plugins/font-loader';

export default {
	name: 'font-picker',
	components: { },
	data() { return {
		fonts,
		copyValue: this.value
	}},
	props: {
		value: { type: String, default: "" }
	},
	computed: {
		selectedFont: function() {
			const font = this.fonts.find((font) => font.fontName == this.selectedFontName);
			return font.name;
		},
		selectedFontName: {
			get: function() { 
				return this.copyValue;
			},
			set: function(newValue) { 
				this.copyValue = newValue;
				this.$emit('input', newValue); 
			}
		}
	},
}
</script>
