<template>
<div></div>
</template>


<script>
import { loadFonts } from '@/plugins/font-loader';

export default {
	name: 'font-loader',
	created() {
		loadFonts().catch((error) => { 
			console.log(error.toString())
			this.$buefy.snackbar.open({
				duration: 6000,
				message: this.$t('FONTS.ERROR_LOADING_MESSAGE'),
				type: 'is-danger',
				cancelText: this.$t('BUTTONS.CANCEL'),
				position: 'is-bottom',
				actionText: null
			})
		});
	},
}
</script>
