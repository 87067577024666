'use strict';

import WebFontLoader from 'webfontloader';
import availableFonts from '@/assets/local/data/fonts.json';

export const fonts = availableFonts;

export const loadFonts = () => new Promise((resolve, reject) => {

	// load all fonts
	WebFontLoader.load({
		custom: {
			urls: [process.env.BASE_URL + 'static/fonts/fonts.css'],
			families: fonts.map(f => f.fontName),
		},

		// options
		timeout: 10000,
		active() {
			const missing = fonts.filter(f => !f.loaded);
			!missing.length ? resolve() : reject(missing);
		},
		inactive() {
			reject(fonts);
		},
		fontactive(fontName) {
			const font = fonts.find(f => f.fontName === fontName);
			font.loaded = true;
		},
		fontinactive(fontName) {
			console.log(`Couldn't load font "${fontName}"`);
		},
	});
});
