'use strict'
const pako = require('pako');

function colorsChanged(previousColors, newColors) {
    if (!previousColors && newColors) return true;
    for (let colorKey in previousColors) {
        if (previousColors[colorKey] != newColors[colorKey]) return true;
    }
    return false;
}

function colorSvgString(svgString, colors) {
    
    let coloredString = svgString
    for (const colorKey in colors) {
        const regex = new RegExp("CC" + colorKey.slice(1),'g');
        coloredString = coloredString.replace(regex, colors[colorKey]);
    }
    const blob = new Blob([coloredString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return { url, string: coloredString };
}

function prepareColors(svgString) {
    svgString = svgString.replace(/"#([0-9a-fA-F]{3})"/g, '"#$1$1"');

    svgString = svgString.replace(/#([0-9a-fA-F]{6})/g, 'CC$1');
    return svgString
}

function loadSvg(svgUrl) {
    if (svgUrl.endsWith('svg')) {
        return new Promise((resolve, reject) => {
            fetch(svgUrl)
            .then((response) => response.text())
            .then((svgString) => {
                svgString = prepareColors(svgString);
                resolve(svgString);
            })
            .catch((err) => reject(err));
        });
    } else {
        return new Promise((resolve, reject) => {
            fetch(svgUrl)
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
                const data = new Uint8Array(buffer);
                let svgString = pako.inflate(data, { to: 'string' });
                svgString = prepareColors(svgString);
                resolve(svgString)
            })
            .catch((err) => reject(err));
        })
    }
}

function computeCoverSize(imageWidth, imageHeight, documentWidth, documentHeight) {
    const ratioWidth = documentWidth/imageWidth;
    const ratioHeight = documentHeight/imageHeight;
    const ratio = Math.max(ratioWidth, ratioHeight);

    const width = Math.round(ratio*imageWidth);
    const height = Math.round(ratio*imageHeight);

    return { width, height };
}

function makeHitformFunction(hitformString, scale) {
    if (hitformString === undefined) return undefined

    const numbers = [...hitformString.matchAll(/-?\d+\.?\d*e?-?\d*?/g)].map((x) => parseFloat(x[0]))
    return function(ctx) {

        ctx.beginPath();
        let x = 0;
        let y = 0;

        let index = 0;
        let numberIndex = 0;
        // let debugPath = ''
        while (index < hitformString.length) {
            const command = hitformString[index];
            switch (command) {
                case ' ': {
                    break;
                }

                case 'M': {
                    x = numbers[numberIndex];
                    ++numberIndex;
                    y = numbers[numberIndex];
                    ++numberIndex;
                    ctx.moveTo(scale*x, scale*y);
                    // debugPath += ` M ${x} ${y}`
                    break;
                }
                case 'm': {
                    const dx = numbers[numberIndex];
                    ++numberIndex;
                    const dy = numbers[numberIndex];
                    ++numberIndex;
                    x += dx
                    y += dy
                    ctx.moveTo(scale*x, scale*y);
                    // debugPath += ` m ${x} ${y}`
                    break;
                }
                
                case 'L': {
                    x = numbers[numberIndex];
                    ++numberIndex;
                    y = numbers[numberIndex];
                    ++numberIndex;
                    ctx.lineTo(scale*x, scale*y);
                    // debugPath += ` L ${x} ${y}`
                    break;
                }
                
                case 'C': {
                    // cp1x, cp1y, cp2x, cp2y, x, y
                    const cp1x = numbers[numberIndex];
                    ++numberIndex;
                    const cp1y = numbers[numberIndex];
                    ++numberIndex;
                    const cp2x = numbers[numberIndex];
                    ++numberIndex;
                    const cp2y = numbers[numberIndex];
                    ++numberIndex;
                    x = numbers[numberIndex];
                    ++numberIndex;
                    y = numbers[numberIndex];
                    ++numberIndex;
                    ctx.bezierCurveTo(scale*cp1x, scale*cp1y, scale*cp2x, scale*cp2y, scale*x, scale*y);
                    // debugPath += ` C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${x} ${y}`
                    break;
                }
                
                case 'c': {
                    const dcp1x = numbers[numberIndex];
                    ++numberIndex;
                    const dcp1y = numbers[numberIndex];
                    ++numberIndex;
                    const dcp2x = numbers[numberIndex];
                    ++numberIndex;
                    const dcp2y = numbers[numberIndex];
                    ++numberIndex;
                    const dx = numbers[numberIndex];
                    ++numberIndex;
                    const dy = numbers[numberIndex];
                    ++numberIndex;
                    ++index;
                    ctx.bezierCurveTo(scale*(x + dcp1x), scale*(y + dcp1y), scale*(x + dcp2x), scale*(y + dcp2y), scale*(x + dx), scale*(y + dy));
                    // debugPath += ` c ${dcp1x} ${dcp1y} ${dcp2x} ${dcp2y} ${dx} ${dy}`
                    x += dx;
                    y += dy;
                    break;
                }
                
                case 'Z': {
                    // ctx.closePath();
                    // debugPath += ` Z`
                    break;
                }
                case 'z': {
                    // ctx.closePath();
                    // debugPath += ` z`;
                    break;
                }
            }
            ++index;
        }
        // console.log(debugPath)
        ctx.closePath();
        ctx.fillStrokeShape(this);
        if (numberIndex < numbers.length) {
            console.log('Unused numbers ', numbers.length - numberIndex)
        }
    }
}

export { colorsChanged, colorSvgString, loadSvg, computeCoverSize, makeHitformFunction }