'use strict'

export const jsonToURI = (json) => encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (urijson) => JSON.parse(decodeURIComponent(urijson));

function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function grayScale(r, g, b) {
    return 0.30*r + 0.59*g + 0.11*b;
}

export function contrast(rgb1, rgb2) {
    var lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
    var lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05)
         / (darkest + 0.05);
}

// From https://gist.github.com/comficker/871d378c535854c1c460f7867a191a5a#file-hex2rgb-jsà
export function HEX2RGB (hex) {
    if (hex.charAt(0) === '#')
        hex = hex.substr(1);

    if ((hex.length < 2) || (hex.length > 6)) return false;
    let values = hex.split('');
    let color = { r: 0, g: 0, b: 0 };

    if (hex.length === 2) {
        color.r = parseInt(values[0].toString() + values[1].toString(), 16);
        color.g = color.r;
        color.b = color.r;
    } else if (hex.length === 3) {
        color.r = parseInt(values[0].toString() + values[0].toString(), 16);
        color.g = parseInt(values[1].toString() + values[1].toString(), 16);
        color.b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
        color.r = parseInt(values[0].toString() + values[1].toString(), 16);
        color.g = parseInt(values[2].toString() + values[3].toString(), 16);
        color.b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
        return false;
    }
    return color;
}

// From https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export const pSBC=(p,c0,c1,l)=>{
    let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    const pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
            [r,g,b,a]=d=d.split(","),n=d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
    h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
}

export const exportComponentLabel = (symbol, locale, default_locale) => {
    if (symbol.label === undefined) return undefined;
    if (symbol.label[locale] !== undefined)
        return symbol.label[locale];
    
    if (symbol.label[default_locale] !== undefined)
        return symbol.label[default_locale];

    return symbol.label[Object.keys(symbol.label)[0]];
}