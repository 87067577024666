<template>
    <b-dropdown aria-role="list" :triggers="['hover', 'click']" expanded position='is-bottom-left' mobile-modal>
        <template #trigger="{ active }">
            <b-button type="is-complete-ghost" :icon-right="active ? 'menu-up' : 'menu-down'" icon-left='information-outline'/>
        </template>

        <b-dropdown-item class='is-version' :custom='true'>
            <span class='has-text-primary'>{{ $t('CHANGELOG.VERSION') }}</span>
            <span class='has-text-grey'>{{ version }}</span>
            <!-- <span class='has-text-grey' style='font-weight:normal; font-size:small'>(2021-07-08)</span> -->
        </b-dropdown-item>
        <!-- <b-dropdown-item has-link>
            <hr class='navbar-divider'/>
            <router-link :to="{ name: 'changelog', params: { lang: $i18n.locale} }">
                {{ $t('CHANGELOG.CHANGELOG') }}
            </router-link>
        </b-dropdown-item>

        <b-dropdown-item>
            {{ $t('CHANGELOG.CONTRIBUTE') }}
        </b-dropdown-item>
        <b-dropdown-item>
            {{ $t('CHANGELOG.BUG_REPORT') }}
        </b-dropdown-item> -->
    </b-dropdown>
</template>


<script>

import packageFile from '../../package.json';

export default {
	name: 'navbar-info',
	data() { return {
        version: packageFile.version
	}},
	computed: {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
.is-version {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    font-weight: bold;
}

</style>