<template>
	<div>
		<div class='row is-justify-content-center' style='margin-top:20px'>
			<div class='symbol-preview'>
				<div class='mask-container' v-if='mask' :style='{ width: Math.round(svgMask.scaledWidth*svgMask.scale) + "px", height: Math.round(svgMask.scaledHeight*svgMask.scale) + "px" }'>
					<svg :viewBox='maskViewBox' :width='Math.round(svgMask.scaledWidth*svgMask.scale)' :height='Math.round(svgMask.scaledHeight*svgMask.scale)'>
						<path :d="maskLeftRectangle" style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.99999994;stroke-opacity:1"/>
						<path :d="maskRightRectangle" style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.99999994;stroke-opacity:1"/>
						<path :d="maskTopRectangle" style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.99999994;stroke-opacity:1"/>
						<path :d="maskBottomRectangle" style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.99999994;stroke-opacity:1"/>
					</svg>
				</div>
				<img :src="`/static/${type}/${selectedSymbol.preview}`" @click='openGallery'/>
			</div>
			<b-tooltip :label="$t('EDIT.TIPS.DOWNLOAD')" style='align-self: self-end'>
				<b-button type='is-text' tag="a" :href="`/static/${type}/${selectedSymbol.filename}`" target='_blank' download
					icon size="is-medium" icon-left="download">
				</b-button>
			</b-tooltip>
		</div>
		<p class='preview-label' v-if='selectedSymbolLabel'>{{ selectedSymbolLabel }}</p>
		
		<div class='row is-justify-content-center'>
			<b-button @click='openGallery' icon-left="magnify">
				{{ $t('BUTTONS.OPEN_GALLERY')}}
			</b-button>
			<b-tooltip :label="$t('EDIT.TIPS.RANDOM_SELECTION')">
				<b-button type='is-text' @click='randomSymbol()' icon-left='dice-multiple' size='is-medium'/>
			</b-tooltip>
		</div>

		<div class='row' v-if='Object.keys(symbolInputColors).length > 0'>
			<palette-picker v-model='symbolInputColors' :key='rerenderColors' :dropColorEnabled='true' />
		</div>
	</div>
</template>


<script>
	import { computeCoverSize } from '@/vida/components/svgutils.js';

	import symbols from '@/assets/local/data/symbols.json';
	import backgrounds from '@/assets/local/data/backgrounds.json';

	import PalettePicker from '@/components/pickers/PalettePicker.vue';
	import SymbolsGallery from '@/components/pickers/ModalGallery.vue';
	import { DEFAULT_LOCALE } from '@/plugins/i18n';
	import { exportComponentLabel } from '@/plugins/utils';

	backgrounds.reverse();

	export default {
		name: 'symbol-picker',
		components: { PalettePicker },
		props: {
			value: { id: String, colors: Object },
			colors: {},
			symbolPicker: { type: Boolean, default: true },
			filters: { String, default:'' },
			mask: { type:Boolean, default:false },
			documentWidth: { type: Number, required: false },
			documentHeight: { type: Number, required: false }
		},
		data() { return {
			symbols,
			backgrounds,
			previewSize: 120,
			copyValue: this.value,
			rerenderColors: 0
		}},
		computed: {
			svgMask:function() {
				const { width, height } = this.selectedSymbol;
				const newSize = computeCoverSize(width, height, this.documentWidth, this.documentHeight);
				const scale = newSize.width > this.documentWidth ? newSize.width / width :newSize.height / height;

				const cropWidth = newSize.width - this.documentWidth;
				const cropHeight = newSize.height - this.documentHeight;
				const scaledHeight = height*scale;
				const scaledWidth = width*scale;
				const svgScale = scaledHeight > scaledWidth ? this.previewSize/scaledHeight : this.previewSize/scaledWidth;
				return { cropWidth, cropHeight, scaledWidth, scaledHeight, scale: svgScale };
			},
			maskLeftRectangle: function() {
				const { scale, scaledHeight, cropWidth } = this.svgMask;
				return `M 0,0 v ${scale*scaledHeight} h ${scale*cropWidth/2} v ${-scale*scaledHeight} Z`
			},
			maskBottomRectangle: function() {
				const { scale, scaledWidth, scaledHeight, cropHeight } = this.svgMask;
				return `M 0, ${scale*(scaledHeight - cropHeight / 2)} h ${scale*scaledWidth} v ${scale*cropHeight/2} h ${-scale*scaledWidth} Z`
			},
			maskTopRectangle: function() {
				const { scale, scaledWidth, cropHeight } = this.svgMask;
				return `M 0,0 h ${scale*scaledWidth} v ${scale*cropHeight/2} h ${-scale*scaledWidth} Z`
			},
			maskRightRectangle: function() {
				const { scale, scaledHeight, scaledWidth, cropWidth } = this.svgMask;
				return `M ${scale*(scaledWidth - cropWidth/2)}, 0 v ${scale*scaledHeight} h${scale*cropWidth/2} v ${-scale*scaledHeight} Z`
			},
			maskViewBox: function() {
				const { scale, scaledHeight, scaledWidth } = this.svgMask;
				return `0 0 ${Math.round(scale*scaledWidth)}${Math.round(scale*scaledHeight)}`;
			},
			filtersList: function() {
				if (this.filters === '') return Array();
				return this.filters.split(',');
			},
			list: function() {
				if (this.symbolPicker) {
					return this.symbols.filter((symbol) => {
						if (this.filtersList.length == 0) return true;
						for (let filter of this.filtersList) {
							if (symbol.tags.split(',').includes(filter)) return true;
						}
						return false;
					}).reverse();
				}
				return this.backgrounds;
			},
			symbolInputId: {
				get: function(){
					console.log('id', this.copyValue.id)
					return this.copyValue.id;
				},
				set: function(newValue) {
					if (newValue === this.copyValue.id) return;
					this.copyValue.id = newValue;
					this.copyValue.colors = this.list.find((s) => s.id == newValue).colors;
					++this.rerenderColors;
					this.$emit('input', { id: newValue, colors: this.copyValue.colors });
				}
			},
			symbolInputColors: {
				get: function(){
					return this.copyValue.colors;
				},
				set: function(newValue) {
					let changed = false;
					for (let key in newValue) {
						if (this.copyValue.colors[key] && this.copyValue.colors[key] === newValue[key]) continue;
						changed = true;
					}
					if (!changed) return;
					this.copyValue.colors = newValue;
					++this.rerenderColors;
					this.$emit('input', { id: this.value.id, colors: newValue });
				}
			},
			selectedSymbol: function() {
				return this.list.find((s) => s.id == this.value.id);
			},
			selectedSymbolLabel: function() {
				return exportComponentLabel(this.selectedSymbol, this.$i18n.locale, DEFAULT_LOCALE);
			},
			type: function() {
				if (this.symbolPicker)
					return 'symbols';
				else
					return 'backgrounds'
			}
		},
		methods: {
			randomSymbol() {
				const randomIndex = Math.floor(Math.random()*this.list.length);
				this.symbolInputId = this.list[randomIndex].id;
				// this.symbolInputId = this.list[index].id;
				// console.log(index);
				// ++index;
			},
			openGallery() {
				this.$buefy.modal.open({
					parent: this,
					component: SymbolsGallery,
					hasModalCard: true,
					trapFocus: true,
					props: { 
						title: this.symbolPicker ? this.$tc('EDIT.COMPONENTS.SVGS', this.list.length) : this.$tc('BACKGROUND_IMAGES.LABEL', this.list.length),
						message: this.$t('SVGS.CONTRIBUTE', { email: this.$localConfig.email }),
						list: this.list,
						pathToPreview: `/static/${this.type}/`,
					},
					events: {
						input: (value) => this.symbolInputId = value
					},
					canCancel: ['escape', 'outside']
				})
			}
	}
}
</script>

<style>
.symbol-preview {
	position: relative;
}

.symbol-preview img {
	width: 120px;
	height: 120px;
	object-fit: contain;
}

.mask-container {
	position: absolute;
	top: -7px;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	overflow: hidden;
	opacity: 0.5;
}

.preview-label {
	font-style: italic;
	color: var(--primary-color);
}
</style>
