aa<template>
    <div>
        <vida-navbar active='backgrounds' />
        <section class="hero is-primary">
            <div class="hero-body">
                <h1 class="title">{{ $tc('BACKGROUND_IMAGES.LABEL', 2)}}</h1>
                <p class='subtitle'>{{ $t('BACKGROUND_IMAGES.PAGE_DESCRIPTION') }}</p>
            </div>
        </section>

        <section>
            <svg-colorable-gallery path='backgrounds' :elements="backgrounds" /> 
        </section>
    </div>
</template>

<script>
import VidaNavbar from '@/components/navbar'
import backgrounds from '@/assets/local/data/backgrounds.json';
import SvgColorableGallery from '../components/SvgColorableGallery.vue';

export default {
    name: 'backgrounds',
    metaInfo: function() {
        return ({
            title: `${this.$t("NAVBAR.BACKGROUNDS")} - ${this.$localConfig.id}`,
            titleTemplate: '%s | Aktivisda.earth',
			meta: [
				{ vmid: 'description', name: 'description', content: `${this.$t("BACKGROUNDS_IMAGES.PAGE_DESCRIPTION")}`  },
				{ property: 'og:title', vmid: 'og:title', content: `${this.$t("NAVBAR.BACKGROUNDS")} | ${this.$localConfig.id}.aktivisda` },
				{ property: 'og:type', vmid: 'og:type', content: 'website' },
				{ property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
				{ property: 'og:description', vmid: 'og:description', content: `${this.$t("BACKGROUNDS_IMAGES.PAGE_DESCRIPTION")}` },
			]				
        });
    },
    components: { VidaNavbar, SvgColorableGallery },
    data: () => ({
        backgrounds,
    })
};
</script>