'use strict';

import Vue from 'vue';
import Router from 'vue-router';

import Welcome from '@/pages/Welcome.vue';
import Symbols from '@/pages/Symbols.vue';
import Backgrounds from '@/pages/Backgrounds.vue';
import Fonts from '@/pages/Fonts.vue';
import Colors from '@/pages/Colors.vue';
import About from '@/pages/About.vue';
import Editor from '@/pages/Editor.vue';
// import Changelog from '@/pages/Changelog.vue';
import RouterView from '@/components/routerview.vue';

Vue.use(Router);

import { loadLanguage, DEFAULT_LOCALE } from '@/plugins/i18n';
const langs = require('@/assets/langs.json');

const langsArray = langs.map((lang) => lang.code);

const router = new Router({
    mode: 'history',
    routes: [
    {
        name: 'main',
        path: "/:lang?/",
        component: RouterView,
        beforeEnter(to, from, next) {
            const newPath = to.path.replace(/\/\//g, '/');
            if (newPath !== to.path) {
                console.log('newPath ', newPath)
                next(newPath);
                return
            }
            const lang = to.params.lang;
            console.log('lang ', lang)
            if (!langsArray.includes(lang)) return next(DEFAULT_LOCALE);
            console.log('load ', lang);
            loadLanguage(lang);
            return next();
        },
        children: [
        {
            path: '/',
            name: 'welcome',
            component: Welcome,
        },
        {
            path: 'symbols',
            name: 'symbols',
            component: Symbols,
        },
        {
            path: 'backgrounds',
            name: 'backgrounds',
            component: Backgrounds,
        },
        {
            path: 'colors',
            name: 'colors',
            component: Colors,
        },
        {
            path: 'fonts',
            name: 'fonts',
            component: Fonts,
        },
        {
            path: 'about',
            name: 'about',
            component: About,
        },
        // {
        //     path: 'changelog',
        //     name: 'changelog',
        //     component: Changelog,
        // },
        {
            path: 'edit/:templateId/:exportType(png|jpg|template)?',
            name: 'editor',
            component: Editor,
        }]
    }],
});

// router.beforeEach((to, from, next) => {
//     const newPath = to.path.replace(/\/\//g, '/');
//     if (newPath !== to.path) {
//         next(newPath);
//         return;
//     }
//     next();
// });

export default router;
