<template>
    <div class='modal-card' style="width:auto">
        <header class='modal-card-head'>
            <p class='modal-card-title'>
                {{ title }}
            </p>
            <button class="delete" aria-label="close" @click='$emit("close")'/>
        </header>

        <section class='modal-card-body'>
            <article class='message is-info'>
                <div class='message-body'  v-html="message"></div>
            </article>
            <div class='row is-justify-content-center is-flex-wrap' style='padding: 20px 0px' v-if='availableTags'>
                <b-field>
                    <b-checkbox-button 
                        v-for='tag, index in availableTags'
                        v-model='choosenTags'
                        :key='index'
                        :native-value="tag">
                        <span>{{ $t('TAGS.' + tag ) }}</span>
                    </b-checkbox-button>
                </b-field>
            </div>
            <div class='container is-flex is-flex-wrap-wrap is-justify-content-space-around'>
                <div class="gallery-card card box is-flex is-flex-direction-column is-justify-content-space-between"
                    style='width:150px;margin:5px'
                    v-for="element in augmentedFilteredElements" :key="element.id"
                    @click='$emit("input", element.id);$emit("close")'>

                    <div class="card-image">
                        <figure class="image contain-image">
                            <img :src="pathToPreview + '/' + element.preview" :alt="element.description"/>
                        </figure>
                    </div>
                    <div class="card-content">  
                        {{ element.localLabel }}
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { DEFAULT_LOCALE } from '@/plugins/i18n';
import { exportComponentLabel } from '@/plugins/utils';

export default {
    name: 'gallery',
    props: {
        value: { type: String },
        title: { type: String },
        message: { type: String },
        pathToPreview: { type: String },
        list: { type: Array },
    },
    data: () => ({
        choosenTags: []
    }),
    computed: {
        filteredElements() {
            if (this.choosenTags.length === 0) return this.list;
            return this.list.filter((element) => {
                if (!element['tags']) return false;
                const tags = element['tags'].split(',');
                for (let t of tags) {
                    if (this.choosenTags.includes(t)) return true;
                }
                return false;
            });
        },
        augmentedFilteredElements() {
            if (!this.filteredElements) return [];
            return this.filteredElements.map((element) => {
                return { ...element, localLabel: exportComponentLabel(element, this.$i18n.locale, DEFAULT_LOCALE)}
            })
        },
        availableTags() {
            const all_tags = [];
            for (let k=0;k<this.list.length;++k) {
                const element = this.list[k];
                let tags = element['tags'];
                if (!tags) continue;
                tags = tags.split(',')
                for (let t of tags) {
                    if  (all_tags.includes(t)) continue;
                    all_tags.push(t);
                }
            }
            return all_tags;
        }

    }
}
</script>
