<template>
	<div class='input-components'>
		<template v-if='!isUndefined'>
			<component-toolbar 
				@remove='removeComponent'
				@forward='++zIndexValue'
				@backward='--zIndexValue'
				@duplicate='$emit("duplicate")'
				:forwardEnabled='options.zIndex < nbComponents'
				:backwardEnabled='options.zIndex > 1'/>
			<symbol-picker v-model='symbolValue' :symbolPicker='true' :filters='options.filters'/>
			
			<number-picker :min="0" :max="200" v-model="scaleValue" unit='%'
				:prepend="$t('COMMON.SMALL')" :append="$t('COMMON.BIG')"/>
			
			<position-picker :width="width" :height="height" v-model="positionValue"/>

			<number-picker :min="-180" :max="180" v-model="angleValue" unit='°'
				:prepend="'-180°'" :append="'+180°'"/>
		</template>
	</div>
</template>


<script>
import symbols from '@/assets/local/data/symbols.json';
import { fonts } from '@/plugins/font-loader';
import SymbolPicker from '@/components/pickers/SymbolPicker.vue';
import PositionPicker from '@/components/pickers/PositionPicker.vue';
import NumberPicker from '@/components/pickers/NumberPicker.vue';
import ComponentToolbar from '@/components/pickers/ComponentToolbar.vue';
// Todo gérer les qr codes

export default {
	name: 'svg-input-component',
	components: { SymbolPicker, PositionPicker, NumberPicker, ComponentToolbar },
	props: {
		width: Number,
		height: Number,
		fieldLabel: String,
		// TODO add values validators
		options: {
			symbol: { name: { type: String, default: '' }, colors: { type:  Object, default: {}}},
			filters: { type: Array, default: [] },
			scale: { type: Number, default: 10 },
			position: { 
				x: { type: Number, default: 1 },
				y: { type: Number, default: 1 },
			},
			angle: { type: Number, default: 0 },
			zIndex: { type: Number, default: 1 },
		},
		nbComponents: { type: Number, defalut: 1 },
	},
	data: () => ({
		fonts,
		symbols,
	}),
	computed: {
		scaleValue: {
			get() { return this.options.scale; },
			set(newValue) { if (newValue !== this.options.scale) this.$emit('update', { scale: newValue }) }
		},
		zIndexValue: {
			get() { return this.options.zIndex; },
			set(newValue) {
				newValue = Math.max(newValue, 1);
				newValue = Math.min(newValue, this.nbComponents);
				if (newValue === this.options.zIndex) return;
				this.$emit('update', { zIndex: newValue })
				this.options.zIndex = newValue;
			}
		},
		positionValue: {
			get() { return this.options.position; },
			set(newValue) { 
				if (newValue.x !== this.options.position.x || newValue.y !== this.options.position.y) 
					this.$emit('update', { position: newValue }) }
		},
		symbolValue: {
			get: function(){ return { 'id': this.options.symbol.id, 'colors': this.options.symbol.colors }},
			set: function(newValue){ if (newValue !== this.symbolValue) this.$emit('update', { symbol: newValue }); }
		},
		angleValue: {
			get: function(){ return  this.options.angle },
			set: function(newValue){ if (newValue !== this.options.angle) this.$emit('update', { angle: newValue }); }
		},
		isUndefined: function() {
			return Object.keys(this.options).length === 1;
		}
	},
	methods: {
		removeComponent() {
			// TODO confirm box ? Oui en attendant le undo/redo
			this.$emit('remove');
		}
	}
};
</script>
