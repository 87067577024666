<template>
  <div id="app">
    <!-- <v-app fluid class="ma-0 pa-0"> -->

      <router-view/>
      <font-loader/>
    <!-- </v-app> -->
  </div>
</template>

<script>

import FontLoader from '@/components/fontloader.vue';

export default {
  name: 'App',
  metaInfo: function() {
    return ({
        title: this.$localConfig.id,
        titleTemplate: '%s | Aktivisda.earth',
        htmlAttrs: {
            lang: 'fr',
        },
        meta: [
            { charset: 'utf-8' },
            { vmid: 'description', name: 'description', content: `${this.$localConfig.description}. Rendu possible grâce au site Aktivisda.`  },
            { property: 'og:title', vmid: 'og:title', content: `${this.$localConfig.id} - Modifie tes propres visuels` },
            { property: 'og:type', vmid: 'og:type', content: 'website' },
            { property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
            { property: 'og:image', vmid: 'og:image', content: `${this.$localConfig.url}/static/rs-preview.png` },
            { property: 'og:description', vmid: 'og:description', content: `${this.$localConfig.description}. Rendu possible grâce au site open source Aktivisda.` },
            { property: 'og:site_name', vmid: 'og:site_name', content: `${this.$localConfig.id} | Aktivisda.earth` },
        ]
    });
  },
  components: { FontLoader },
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
