<template>
	<div class='row'>
		<div class='row' style='flex-grow:1; margin-right: 5px; margin-top: auto; margin-bottom: auto'>
			<color-picker v-for='(c, index) in selectedPallet' :randomShortcut='false'
				:key='index + "-" + rerenderColors'
				:value='selectedPallet[index]' @input='($event) => updateColor(index, $event)'
				:dropColorEnabled='dropColorEnabled' />
		</div>
		<div>
			<b-tooltip :label='$t("PALETTES.CHOOSE")' style='margin: 2px'>
				<b-button @click="editPallet" icon icon-left="square-edit-outline"></b-button>
			</b-tooltip>

			<b-tooltip :label="$t('PALETTES.SHUFFLE')" style='margin: 2px'>
				<b-button @click="shufflePallet" icon icon-left="sync" :disabled='paletteSize === 1'></b-button>
			</b-tooltip>

			<b-tooltip :label="$t('EDIT.TIPS.RANDOM_SELECTION')" style='margin: 2px' position='is-left'>
				<b-button @click="randomPallet" icon icon-left="dice-multiple" :disabled='availablePalettes.length === 0'></b-button>
			</b-tooltip>
		</div>
	</div>

</template>


<script>
import pallets from '@/assets/local/data/palettes.json';
import ColorPicker from '@/components/pickers/ColorPicker.vue';
import ModalPalettes from '@/components/pickers/ModalPalettes.vue';

export default {
	name: 'palette-picker',
	components: { ColorPicker },
	props: {
		value: Object,
		dropColorEnabled: { type: Boolean, default: false }
	},
	data() { return {
		pallets,
		openedModal: false,
		copyValue: this.value,
		rerenderColors: 0
	}},
	computed: {
		selectedPallet() {
			if (!this.copyValue) return [];
			return Object.values(this.copyValue);
		},
		selectedKeys() {	 
			if (!this.copyValue) return [];
			return Object.keys(this.copyValue);
		},
		availablePalettes() {
			const av = this.pallets.filter((pallet) => pallet.length >= this.selectedPallet.length).map((palette) => palette.slice(0, this.paletteSize));
			return [...new Set(av)]; // removing duplicates
		},
		paletteSize() {
			return this.selectedPallet.length;
		}
	},
	methods: {
		shufflePallet() {
			if (this.paletteSize == 2) {
				this.emitPalette([this.selectedPallet[1], this.selectedPallet[0]]);
				return;
			}

		this.selectedPallet.sort(() => Math.random() - 0.5);
			this.emitPalette(this.selectedPallet);
		},
		editPallet() {
			this.$buefy.modal.open({
				parent: this,
				component: ModalPalettes,
				hasModalCard: true,
				trapFocus: true,
				props: { 
					title: this.$tc('PALETTES.LABEL', this.pallets.length),
					palettes: this.availablePalettes
				},
				events: {
					input: (palette) => this.selectPalette(palette)
				},
				canCancel: false
			})
		},
		randomPallet() {
			const randomPalletIndex = Math.floor(Math.random()*this.availablePalettes.length);
			this.emitPalette(this.availablePalettes[randomPalletIndex]);
		},
		selectPalette(palette) {
			this.emitPalette(palette);
		},
		emitPalette(pallet) {
			const output = {};
			for (let k=0; k<this.selectedKeys.length; ++k) {
				output[this.selectedKeys[k]] = pallet[k];
			}
			this.copyValue = output;
			this.$emit('input', this.copyValue);
			++this.rerenderColors;
		},
		updateColor(index, evt) {
			const pallet = this.selectedPallet;
			pallet[index] = evt;
			const output = {};
			for (let k=0; k<this.selectedKeys.length; ++k) {
				output[this.selectedKeys[k]] = pallet[k];
			}
			this.copyValue = output;
			this.$emit('input', this.copyValue);
		},
	},
	mounted() {
	},
}
</script>


<style scoped>
.pallet-list-thumb {
	cursor: pointer;
	height: 40px;
	width: auto;
	border: 1px solid #aaa;
	margin: 3px 1px;
}

.pallet-list-thumb .label {
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: bold;
	line-height: 38px;
	color: white;
	opacity: 0;
}

.pallet-list-thumb .label:hover {
	opacity: 1;
	mix-blend-mode: exclusion;
}
</style>
