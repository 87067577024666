<template>
	<b-slider :min="intMin" :max="intMax" v-model="sliderValue">
		<b-slider-tick :value="intMin" ticks>{{ prepend }}</b-slider-tick>
		<b-slider-tick v-if="intMin < 0" :value="0" ticks>{{ $t('COMMON.CENTER') }}</b-slider-tick>
		<b-slider-tick :value="intMax" ticks>{{ append }}</b-slider-tick>
	</b-slider>
</template>


<script>
export default {
	name: 'number-picker',
	props: {
		value: Number,
		min: { type: Number, default: 0 },
		max: { type: Number, default: 100 },
		unit: { type: String, default: ''},
		prepend: { type: String, default: '' },
		append: { type: String, default: '' }
	},
	data: () => ({
		incrementation: false
	}),
	methods: {
		startIncrementation(value) {
			this.incrementation = true;
			this.sliderValue += value;
			setTimeout(() => this.continueIncrementation(value), 500);
		},
		continueIncrementation(value) {
			if (!this.incrementation) return;
			if (this.sliderValue == this.max && value > 0) return;
			if (this.sliderValue == this.min && value < 0) return;
			this.sliderValue += value;
			setTimeout(() => this.continueIncrementation(value), 100);
		},
		stopIncrementation() {
			this.incrementation = false;
		}
	},
	computed: {
		size: function() {
			return Math.ceil(Math.log10(1 + Math.max(Math.abs(this.intMin), Math.abs(this.intMax)))) + (this.intMin < 0);
		},
		sliderValue: {
			get: function(){ return Math.round(this.value); },
			set: function(newValue){
				this.$emit('input', Math.max(Math.min(newValue, this.intMax), this.intMin));
			}
		},
		intMax: function() {
			return Math.ceil(this.max);
		},
		intMin: function() {
			return Math.floor(this.min);
		}
	}
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input.custom-input-number::-webkit-outer-spin-button,
input.custom-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.custom-input-number[type=number] {
  -moz-appearance: textfield;
}

input.custom-input-number {
	text-align: center;
}
input.custom-input-number:focus {
	border-bottom: 2px solid #f6c24a;
}
</style>
