import Vue from 'vue'
import '@/plugins/matomo.js';
import App from '@/App.vue'
import '@/plugins/buefy'
import '@/plugins/localconfig'
import { i18n } from '@/plugins/i18n';
import '@/plugins/i18n-extended';
import '@/assets/styles/main.scss';
import '@/plugins/vue-meta.js';

import router from '@/router';

Vue.config.productionTip = false


new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
