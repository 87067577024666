'use strict'
import InvalidDataError from '@/vida/invaliddataerror.js';

function migrate_0_9_1(template) {
    console.log('migrate to 0.9.1');

    for (let k=0; k<template.components.length; ++k) {
        if (template.components[k].type !== 'text') continue;
        template.components[k].background = {
            "enabled": false,
            "color": "#ffffff",
            "padding": 3
        }
    }
    template.version = '0.9.1';
    return template;
}

const migrations = {
    "0.9.1": migrate_0_9_1
};

function extractVersion(version) {
    const versions = version.split('.');
    if (versions.length != 3) throw new InvalidDataError(`Invalid version ${version}. It should be like 1.0.0`);
    
    const major = versions[0]; 
    const minor = versions[1];
    const patch = versions[2];
    if (isNaN(major) || isNaN(minor) || isNaN(patch)) throw new InvalidDataError(`Invalid version ${version}. It should be like 1.0.0`);

    return { "major": parseInt(major), "minor": parseInt(minor), "patch": parseInt(patch) };
}

function getVersion(template) {
    if (template.version === undefined) throw new InvalidDataError(`Invalid template. It should contain a version field`);
    return extractVersion(template.version);
}

function migrate(template) {
    let { major, minor, patch } = getVersion(template);
    console.log("Template ", template)
    console.log(major, minor, patch)
    for (const migration in migrations) {
        const extractedMigration = extractVersion(migration);
        if (major > extractedMigration.major) continue;
        if (minor > extractedMigration.minor) continue;
        if (patch >= extractedMigration.patch) continue;
        template = migrations[migration](template);
        major = extractedMigration.major; minor = extractedMigration.minor; patch = extractedMigration.patch;
    }
    return template;
}

export default migrate;