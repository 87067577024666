'use strict'

import AbstractVidaComponent from '@/vida/components/abstractvidacomponent.js'
import backgrounds from '@/assets/local/data/backgrounds.json';
import { colorSvgString, colorsChanged, loadSvg, computeCoverSize } from '@/vida/components/svgutils.js';
import Konva from 'konva';
import InvalidDataError from '@/vida/invaliddataerror'

const maxInt = 100000000

export default class BackgroundVidaComponent extends AbstractVidaComponent {

    constructor(vida, id) {
        super(vida, id);
    }
    
    select() {
        // nothing to do
    }

    unselect() {
        // nothing to do
    }

    bringClipperForward() {
        this.konvaClipper.setZIndex(1000);
    }

    internalUpdate(options) {
        return new Promise((resolve) => {
            if (this.inPromise) {
                resolve();
                return;
            }
            if (!this.konvaGroup) {
                this.konvaGroup = new Konva.Group();
                this.vida.registerComponent(this.konvaGroup);
                this.konvaGroup.on('click', () => {
                    this.vida.selectComponent(this.id);
                });
            }
            
            if (!this.konvaClipper) {
                this.konvaClipper = new Konva.Group();
                this.konvaClipper.rect = new Konva.Rect({
                    x: -maxInt, 
                    y: -maxInt,
                    width: 2*maxInt,
                    height: 2*maxInt,
                    fill: '#aeaeae',
                    listening: false,
                    opacity: 0.8
                });
                this.konvaClipper.add(this.konvaClipper.rect);
                this.vida.registerComponent(this.konvaClipper);
            }

            if (options.id) this.id = options.id;

            if (options.background && (!this.backgroundId || this.backgroundId != options.background.id)) {
                const backgroundIndexOf = backgrounds.map(e => e.id).indexOf(options.background.id);
                if (backgroundIndexOf < 0) throw new InvalidDataError(`Unknown background id ${options.background.id}`)
                const backgroundFilename = backgrounds[backgroundIndexOf].filename
                const backgroundUrl = '/static/backgrounds/' + backgroundFilename;
                this.colors = undefined;
                this.inPromise = true;
                loadSvg(backgroundUrl).then((svgString) => {
                    this.svgString = svgString;
                    this.backgroundId = options.background.id;
                    this.inPromise = false;
                    this.internalUpdate(options).then(() => resolve());
                });
                return;
            }
            
            if (options.background && (!this.konvaElement || colorsChanged(this.colors, options.background.colors))) {
                this.colors = options.background.colors;

                if (!options.width) options.width = this.width;
                if (!options.height) options.height = this.height;
                this.width = undefined;
                this.height = undefined;
                if (this.konvaElement) this.konvaElement.destroy();

                console.assert(this.svgString);
                this.inPromise = true;
                Konva.Image.fromURL(colorSvgString(this.svgString, this.colors).url, (image) => {
                    this.konvaElement = image;
                    this.konvaElement.setAttrs({
                        draggable: false,
                        zIndex: 0
                    });
                    
                    this.originalSize = { width: image.width(), height: image.height() };
                    this.konvaGroup.add(this.konvaElement);
                    this.inPromise = false;
                    this.internalUpdate(options).then(() => resolve());
                });
                return;
            }
            
            if (!this.konvaElement) { resolve(); return; }

            if ((options.width && (!this.width || this.width !== options.width))
                || (options.height && (!this.height || this.height !== options.height))) {

                if (options.width) this.width = options.width;
                if (options.height) this.height = options.height;
                
                const { width, height } = computeCoverSize(this.originalSize.width, this.originalSize.height, this.width, this.height);

                this.konvaGroup.setAttrs({
                    clipX: 0,
                    clipY: 0,
                    clipWidth: this.width,
                    clipHeight: this.height,
                });
                
                this.konvaElement.setAttrs({
                    zIndex: 0,
                    width: width,
                    height: height,
                    x: (this.width - width)/2,
                    y: (this.height - height)/2,
                });
            
                const clipX = this.width;
                const clipY = this.height;
                this.konvaClipper.setClipFunc(
                    function (ctx) {
                        ctx.rect(-maxInt, -maxInt, 2*maxInt, maxInt);
                        ctx.rect(-maxInt, -maxInt, maxInt, 2*maxInt);
                        ctx.rect(clipX, -maxInt, maxInt, 2*maxInt);
                        ctx.rect(-maxInt, clipY, 2*maxInt, maxInt);
                    }
                );
                this.vida.draw();
            }
            
            this.konvaElement.zIndex(0);
            this.konvaClipper.zIndex(100000);
            this.vida.draw();
            resolve();
        });
    }
}