<template>
    <div class='container'>
        <p style='padding: 10px 0px'>
        <div class='row is-justify-content-center is-flex-wrap' style='padding: 20px 0px' v-if='availableTags'>
            <b-field>
                <b-checkbox-button 
                    v-for='tag, index in availableTags'
                    v-model='choosenTags'
                    :key='index'
                    :native-value="tag">
                    <span>{{ $t('TAGS.' + tag ) }}</span>
                </b-checkbox-button>
            </b-field>
        </div>
            
        <div class='row' style='margin:auto'>
            <div class="gallery-card card box is-flex is-flex-direction-column"
                style="width:150px; margin: 5px; padding: 10px"
                v-for="(element, key) in augmentedFilteredElements" :key="key"
                @click='openModal(element)'>
                <div class="card-image">
                    <figure class="image contain-image">
                        <img :src="`/static/${path}/${element.preview}`" :alt="element.description"/>
                    </figure>
                </div>
                <div v-if='element.localLabel' class="card-content">  
                    {{ element.localLabel }}
                </div>
            </div>
        </div>

        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-modal v-if="selectedElement && !isLoading" v-model="isModalActive" :width="640" scroll="keep">
            <div class="card">
                <div class="card-image checkerboard-background" style='padding: 15px'>
                    <figure class="image is-4by3">
                        <img v-if="coloredPreview.url" :src="coloredPreview.url"  :alt="selectedElement.description">
                        <img v-else :src="`/static/${path}/${selectedElement.filename}`"  :alt="selectedElement.description">
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content">
                        {{ selectedElement.localLabel }}
                        <br>
                    </div>
                    <hr/>
                    <palette-picker v-model='colors'/>
                </div>
                <footer class="card-footer">
                    <a class="card-footer-item" @click='downloadPng'>{{ $t('EDIT.EXPORT.PNG') }}</a>
                    <a :href="coloredPreview.url" class="card-footer-item" :download="selectedElement.id + '.svg'">{{ $t('EDIT.EXPORT.SVG') }}</a>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
import PalettePicker from '@/components/pickers/PalettePicker.vue';
import { loadSvg, colorSvgString } from '@/vida/components/svgutils.js';
import { DEFAULT_LOCALE } from '@/plugins/i18n';
import { exportComponentLabel } from '@/plugins/utils';
import Canvg from 'canvg';

export default {
    name: 'SvgColorableGallery',
    components: { PalettePicker },
    props: {
        elements: {
            type: Array,
            default: () => {[]}
        },
        path: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isModalActive: false,
        selectedElement: {},
        svgString: {},
        colors: {},
        isLoading: false,
        coloredPreview: {},
        canvas: undefined,
        choosenTags: []
    }),
    methods: {
        openModal(element) {
            if (this.isLoading) return;
                this.isLoading = true;
            this.isModalActive = true;
            if (this.selectedElement.id === element.id) return;
            this.selectedElement = element;
            const colors = {};
            for (let color of Object.keys(this.selectedElement.colors)) {
                colors[color] = this.selectedElement.colors[color];
            }

            loadSvg(`/static/${this.path}/${this.selectedElement.filename}`).then((svgString) => {
                this.isLoading = false;
                this.svgString = svgString;
                this.colors = colors;
            });
        },
        downloadPng() {
            if (!this.coloredPreview) return;
            if(!this.canvas) this.canvas = window.document.createElement('canvas');
            
            const ctx = this.canvas.getContext('2d');
            const canvgElement = Canvg.fromString(ctx, this.coloredPreview.string);

            const widthRatio = 1000/ this.selectedElement.width;
            const heightRatio = 1000/this.selectedElement.height;
            const ratio = Math.max(widthRatio, heightRatio);
            canvgElement.resize(this.selectedElement.width*ratio, this.selectedElement.height*ratio, 'xMidYMid meet');
            canvgElement.render().then(() => {
                const url = this.canvas.toDataURL({ mimeType: 'image/png', quality: 1 });
                const link = document.createElement('a');
                link.download = `${this.selectedElement.id}.png`;
                link.href = url;
                document.body.appendChild(link);
                link.click();

                // // cleanup temporary elements
                document.body.removeChild(link);
            });
        }
    },
    computed: {
        filteredElements() {
            if (this.choosenTags.length === 0) return this.elements.filter(() => true).sort(() => Math.random() - 0.5);
            return this.elements.filter((element) => {
                if (!element['tags']) return false;
                const tags = element['tags'].split(',');
                for (let t of tags) {
                    if (this.choosenTags.includes(t)) return true;
                }
                return false;
            }).sort(() => Math.random() - 0.5);
        },
        augmentedFilteredElements() {
            if (!this.filteredElements) return [];
            return this.filteredElements.map((element) => {
                return { ...element, localLabel: exportComponentLabel(element, this.$i18n.locale, DEFAULT_LOCALE)}
            })
        },
        availableTags() {
            const all_tags = [];
            for (let k=0;k<this.elements.length;++k) {
                const element = this.elements[k];
                let tags = element['tags'];
                if (!tags) continue;
                tags = tags.split(',')
                for (let t of tags) {
                    if  (all_tags.includes(t)) continue;
                    all_tags.push(t);
                }
            }
            return all_tags;
        }
    },
    watch: {
        // whenever question changes, this function will run
        colors: {
            handler: function (newColors) {
                if (!this.svgString) return;
                this.coloredPreview = colorSvgString(this.svgString, newColors);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.checkerboard-background {
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(135deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(135deg, transparent 75%, #ccc 75%);
    background-size: 25px 25px;
    background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
    background-repeat: repeat;
}
</style>