<template>
    <b-dropdown v-if='valid' :triggers="['hover']" aria-role="list">
        <template #trigger>
            <b-button
                label="Export"
                icon-right="menu-down"
                icon-left="download" />
        </template>
        <b-dropdown-item aria-role="listitem" has-link>
            <router-link :to="$router.currentRoute.path + '/png'"> {{ $t('EDIT.EXPORT.PNG')}}</router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link>
            <router-link :to="$router.currentRoute.path + '/jpg'"> {{ $t('EDIT.EXPORT.JPG')}}</router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link v-if='advanced'>
            <router-link :to="$router.currentRoute.path + '/pdf'"> {{ $t('EDIT.EXPORT.PDF')}}</router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" has-link v-if='advanced'>
            <router-link :to="$router.currentRoute.path + '/template'"> {{ $t('EDIT.EXPORT.TEMPLATE')}}</router-link>
        </b-dropdown-item>
    </b-dropdown>
</template>


<script>

export default {
	name: 'export-menu',
	methods: {
		onExport(format) {
			this.$emit('export', format);
		},
	},
    computed: {
        valid: function() {
            return this.$router.currentRoute.params.exportType === undefined;
        }
    },
	props: {
		advanced: Boolean,
	},
	data: () => ({
		pdfDialog: false
	})
}
</script>
