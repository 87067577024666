<template>
    <div>
        <vida-navbar active='colors'/>
        <section class="hero is-primary">
            <div class="hero-body">
                <h1 class="title capitalize">{{ $tc('COLORS.LABEL', 2) }}</h1>
                <p class='subtitle'>{{ $t('COLORS.PAGE_DESCRIPTION') }}</p>
            </div>
        </section>

        <section>
            <div class='container' style='margin-top: 30px'>
                <div class='row'>
                    <section class='color-box' v-for='color, key in colors' :key='key'>
                        <figure class='color-figure' :style="{ backgroundColor: color['html']}">
                            <h4 class='color-name' :style='{ color: color["opposite"] + "!important"}'>
                                <span class='html-color'>{{ color['html'] }}</span>
                            </h4>
                        </figure>
                    </section>
                </div>
            </div>
        </section>

        <section v-if='palettesBySize.length > 0'>
            <h2 class="title">{{ $tc('PALETTES.LABEL', 2) }}</h2>
            <section v-for='palettes, sizeIndex in palettesBySize' :key='sizeIndex'>
                <h3>{{ $t('PALETTES.TITLE_SIZE', { nb: palettes[0].length})}}</h3>
                <div class='container'>
                    <div class='row'>
                        <section class='palette-box' v-for='palette, key in palettes' :key='key'>
                            <figure class='palette-figure'>
                                <div :class='{"palette-color": true,  "tight": palette.length > 5}' v-for='color, colorIndex in palette' :key='colorIndex' :style="{ backgroundColor: '#'+color['html']}">
                                    <h4 class='color-name' :style='{ color: color["opposite"] + "!important"}'>
                                        <span class='html-color'>{{ color['html'] }}</span>
                                    </h4>
                                </div>
                            </figure>
                        </section>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import VidaNavbar from '@/components/navbar'

import colorsList from '@/assets/local/data/colors.json';
import palettesList from '@/assets/local/data/palettes.json';
import { contrast, HEX2RGB } from '@/plugins/utils.js';

export default {
    name: 'fonts',
    metaInfo: function() {
        return ({
            title: `${this.$t("NAVBAR.COLORS")} - ${this.$localConfig.id}`,
            titleTemplate: '%s | Aktivisda.earth',
			meta: [
				{ vmid: 'description', name: 'description', content: `${this.$t("COLORS.PAGE_DESCRIPTION")}`  },
				{ property: 'og:title', vmid: 'og:title', content: `${this.$t("NAVBAR.COLORS")} | ${this.$localConfig.id}.aktivisda` },
				{ property: 'og:type', vmid: 'og:type', content: 'website' },
				{ property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
				{ property: 'og:description', vmid: 'og:description', content: `${this.$t("COLORS.PAGE_DESCRIPTION")}` },
			]				
        });
    },
    data: () => ({
        colorsList,
        palettesList
    }),
    components: { VidaNavbar },
    computed: {
        colors: function() {
            const black = { r: 0, b: 0, g: 0 };
            const white = { r: 255, b: 255, g: 255 };
            
            return this.colorsList.map((color) => {
                const rgb = HEX2RGB(color.html);

                color['opposite'] = contrast(rgb, black) > contrast(rgb, white) ? 'black' : 'white';
                return color;
            });
        },
        palettesBySize: function() {
            const black = { r: 0, b: 0, g: 0 };
            const white = { r: 255, b: 255, g: 255 };

            const results = {};

            this.palettesList.map((palette) => {
                const newPalette = palette.map((color) => ({
                    'html': color.slice(1),
                    'opposite': contrast(HEX2RGB(color), black) > contrast(HEX2RGB(color), white) ? 'black' : 'white'
                }))
                const size = newPalette.length;
                if (results[size] === undefined) results[size] = [];
                results[size].push(newPalette);
            });
            const tab = Object.values(results);
            return tab.sort((a, b) => a[0].length > b[0].length);
        }
    }
};
</script>

<style scoped lang='scss'>
.palette-box, .color-box {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2.5vw;
	background-color: #e2e2e2;
	border-radius: 0.75vw;
	box-shadow: 0 1px 0 0 rgba(19, 19, 19, .1);
	transition: transform 100ms, box-shadow 150ms;
    margin-left: 13px;
    margin-right: 13px;
}

.color-box {
	width: 22.5vw;
	max-width: 233px;
    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: none;
    }
}

.palette-box:hover, .color-box:hover {
	transform: scale(1.05);
	box-shadow: 0px 3px 10px -5px rgba(19, 19, 19, .3);
}

.color-box .color-figure {
    height: 13.5vw;
    max-height: 130px;
    width: 100%;
    display: inline-block; 
    border-radius: 4px; 
    margin-left: 0px;
        
    @media screen and (max-width: 768px) {
        min-height: 90px;
        max-height: none;
    }
}

.color-name {
	position: relative;
	top: 50%;
	text-align: center;
	color: inherit;
    padding: 0 10px;
	-webkit-transform: translateY(-50%);
    margin-top: 0px;
    margin-bottom: 0px;
}

.html-color {
	letter-spacing: 0.6px;
	text-transform: uppercase;
	font-weight: bold;
}

.palette-figure {
	display: flex;
    height: 13.5vw;
    max-height: 130px;
    width: 100%;
    border-radius: 4px; 
    margin-left: 0px;
    border: 2px solid #eee;
    overflow: hidden;
    max-width: 430px;
}

.palette-color {
	height: 100%;
	width: 85px;
}

.palette-color.palette-color.tight .html-color {
    display: none;
}

.palette-color .color-name .html-color {
    font-size: small;
}
</style>
