aa<template>
    <div>
        <vida-navbar active='symbols' />
        <section class="hero is-primary">
            <div class="hero-body">
                <h1 class="title">{{ $tc('SVGS.LABEL', 2) }}</h1>
                <p class="subtitle">{{ $t('SVGS.PAGE_DESCRIPTION') }}</p>
            </div>
        </section>

        <section>
            <svg-colorable-gallery path='symbols' :elements='symbols' />
        </section>
    </div>
</template>

<script>
import VidaNavbar from '@/components/navbar'
import SvgColorableGallery from '@/components/SvgColorableGallery'


import symbols from '@/assets/local/data/symbols.json';
export default {
    name: 'symbols',
    metaInfo: function() {
        return ({
            title: `${this.$t("NAVBAR.SYMBOLS")} - ${this.$localConfig.id}`,
            titleTemplate: '%s | Aktivisda.earth',
			meta: [
				{ vmid: 'description', name: 'description', content: `${this.$t("SVGS.PAGE_DESCRIPTION")}`  },
				{ property: 'og:title', vmid: 'og:title', content: `${this.$t("NAVBAR.SYMBOLS")} | ${this.$localConfig.id}.aktivisda` },
				{ property: 'og:type', vmid: 'og:type', content: 'website' },
				{ property: 'og:url', vmid: 'og:url', content: this.$localConfig.url },
				{ property: 'og:description', vmid: 'og:description', content: `${this.$t("SVGS.PAGE_DESCRIPTION")}` },
			]				
        });
    },
    components: { VidaNavbar, SvgColorableGallery },
    data: () => ({
        symbols,
    }),
};
</script>
